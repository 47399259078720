import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit,AfterViewInit,OnDestroy {

  list : any;
  pageOfItems: Array<any>;
  q:any;
  searchText: string;
  blogPosts : any[] = [];
  currentPage = 1;
  pagination = {
    hasNextPage: false,
    hasPrevPage: false,
    totalPages:1
  }
  isLoading = false;
  isError = false;
  constructor(private router : Router, private data:DataService, private activeRoute:ActivatedRoute) {
    activeRoute.queryParams.subscribe(params=>{
        
        if(params["searchText"]){
            this.searchText = params["searchText"]
        }
    })
   }

  ngOnInit(): void {
    this.getBlogPosts()
    // this.http.get('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/blog').subscribe(res=>{
    //   const data=res;
    //   this.list=data;
    //   this.list=this.list.content

    // })
  }

  ngAfterViewInit(): void {
     
  }

  ngOnDestroy(): void {
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  getData(m){
    this.router.navigate(['/blogdetails'],{queryParams:{id:m}});
  }

  getBlogPosts(){
    this.isLoading = true;
    this.data.getPosts(this.currentPage, this.searchText).subscribe((res:any)=>{
        this.isLoading = false;
        this.blogPosts = res.docs;
        this.pagination = {...res};
        this.currentPage = res?.page;
    },err=>{
        this.isLoading = false;
        this.isError = true;
    })
  }
  searchBlogPosts(){
    this.router.navigate([],{queryParams:{searchText:this.searchText}})
    this.getBlogPosts()
  }

  getPagesArray(){
    return Array(this.pagination?.totalPages || 1).fill(0).map((x, i) => i + 1);
  }

  nextPage(){
    if(!this.pagination.hasNextPage)return;
    this.currentPage += 1;
    this.getBlogPosts();
  }

  previousPage(){
    if(!this.pagination.hasPrevPage)return;
    this.currentPage -= 1;
    this.getBlogPosts();
  }

  moveToPage(page:number){
    this.currentPage = page;
    this.getBlogPosts();
  }

  openPostDetails(postUrl:any){
    this.router.navigate([`/blog-details/${postUrl}`])
  }

  searchNow($event:any){
    if($event.key == 'Enter'){
        this.searchBlogPosts()
    }
  }

  resetSearch(){
    this.searchText = '';
    this.router.navigate([],{queryParams:{searchText:null}})
    this.getBlogPosts()
  }
}
