<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/blog">Blog</a></li>
                <li>{{BlogPostData?.url}}</li>
            </ul> -->
            <h2 style="font-size: 28px;">{{
                BlogPostData?.title
            }}</h2>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row" [style.display]="isLoading || responseError ? 'none' : ''">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">

                    <div class="article-content">
                         <div id="blogDescriptionDiv"></div>
                         <div class="entry-meta mt-5">
                            <ul>
                                <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Category</span>
                                    <a >{{BlogPostData?.category?.name}}</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Posted Date</span>
                                    <a >{{BlogPostData?.createdAt | date:'mediumDate'}}</a>
                                </li>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Last Updated</span>
                                    <a >{{BlogPostData?.updatedAt | date:'mediumDate'}}</a>
                                </li>
                            </ul>
                        </div>
                        </div>
                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-purchase-tag'></i></span>
                            <a class="main-color" role="button" *ngFor="let tag of BlogPostData?.tags?.slice(0,3); let last = last">
                                {{tag?.name?.charAt(0)?.toUpperCase() + tag?.name?.slice(1)}}<ng-container *ngIf="!last">,</ng-container>
                              </a>

                        </div>
                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u={{getPostShareUrl()}}" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?text={{BlogPostData?.title}}&url={{getPostShareUrl()}}" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="article-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile pb-0">
                            <div class="author-profile-title">
                                <img [src]="BlogPostData?.publishedBy?.image" style="object-fit: cover;" class="shadow-sm" alt="image">
                                <div class="author-profile-title-details">
                                    <div class="author-profile-details">
                                        <h4>{{BlogPostData?.publishedBy?.name}}</h4>
                                        <span class="d-block">{{BlogPostData?.publishedBy?.role}}</span>
                                    </div>
                                </div>
                            </div>
                            <p>{{BlogPostData?.publishedBy?.description}}</p>
                        </div>
                    </div> -->
                    <div class="odemy-post-navigation" *ngIf="BlogPostData?.prevPost?.url && BlogPostData?.nextPost?.url">
                        <div class="prev-link-wrapper" >
                            <div class="info-prev-link-wrapper">
                                <a role="button" (click)="openPostDetails(BlogPostData?.prevPost?.url)">
                                    <span class="image-prev">
                                        <img style="width: 125px;height: 60px;" [src]="BlogPostData?.prevPost?.imageUrl" [alt]="BlogPostData?.prevPost?.imageAlt">
                                        <span class="post-nav-title">Prev</span>
                                    </span>
                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title">{{BlogPostData?.prevPost?.title?.slice(0,45) + '...'}}</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">{{BlogPostData?.prevPost?.createAt  | date:'mediumDate'}}</span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="next-link-wrapper" >
                            <div class="info-next-link-wrapper">
                                <a  role="button" (click)="openPostDetails(BlogPostData?.nextPost?.url)">
                                    <span class="next-link-info-wrapper">
                                        <span class="prev-title">{{BlogPostData?.nextPost?.title?.slice(0,45) + '...'}}</span>

                                        <span class="meta-wrapper">
                                            <span class="date-post">{{BlogPostData?.nextPost?.createAt  | date:'mediumDate'}}</span>
                                        </span>
                                    </span>
                                    <span class="image-next">
                                        <img style="width: 125px;height: 60px;"  [src]="BlogPostData?.nextPost?.imageUrl" [alt]="BlogPostData?.nextPost?.imageAlt">
                                        <span class="post-nav-title">Next</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>
                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>
                                        <div class="comment-metadata">
                                            <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>
                                    <div class="reply">
                                        <a  class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
                                            <div class="reply">
                                                <a  class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                        <ol class="children">
                                            <li class="comment">
                                                <article class="comment-body">
                                                    <footer class="comment-meta">
                                                        <div class="comment-author vcard">
                                                            <img src="assets/img/user3.jpg" class="avatar" alt="image">
                                                            <b class="fn">Sarah Taylor</b>
                                                            <span class="says">says:</span>
                                                        </div>
                                                        <div class="comment-metadata">
                                                            <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                                        </div>
                                                    </footer>
                                                    <div class="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                    </div>
                                                    <div class="reply">
                                                        <a  class="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user4.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>
                                        <div class="comment-metadata">
                                            <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>
                                    <div class="reply">
                                        <a  class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                                    <b class="fn">James Anderson</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
                                            <div class="reply">
                                                <a  class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>
                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" placeholder="Your Name*" name="author" required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" placeholder="Your Email*" name="email" required="required">
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" placeholder="Website" name="url">
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..." rows="5" maxlength="65525" required="required"></textarea>
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Search</h3>
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field"   (keydown)="searchNow($event)" id="searchInput" placeholder="Search..." [(ngModel)]="searchText" [ngModelOptions]="{standalone:true}">
                            </label>
                            <button (click)="searchBlogPosts()" class="disabled" type="button"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_odemy_posts_thumb">
                        <h3 class="widget-title">Similar Posts</h3>
                        <article class="item" *ngFor="let article of BlogPostData?.similarArticles">
                            <a  class="thumb" style="width: 150px;" role="button" (click)="openPostDetails(article?.url)">
                                <span ></span>
                                <img [src]="article?.imageUrl" class="fullimage cover bg1 w-100" role="img" [alt]="article?.imageAlt">
                            </a>
                            <div class="info">
                                <span>{{article?.createdAt | date : 'mediumDate'}}</span>
                                <h4 class="title usmall"><a >{{article?.title?.slice(0,35) + '...'}}</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li *ngFor="let category of BlogPostData?.subCategories">
                                <a role="button">{{category?.name?.charAt(0)?.toUpperCase() + category?.name?.slice(1)}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">

                                <a *ngFor="let tag of BlogPostData?.tags"  role="button">{{tag?.name?.charAt(0)?.toUpperCase() + tag?.name?.slice(1)}}</a>

                        </div>
                    </div>
                </aside>
            </div>
        </div>

        <div class="row" *ngIf="isLoading">
            <div class="col-lg-12 col-md-12 my-5" >
                <div class="text-center d-flex justify-content-center align-items-center flex-column gap-3" style="font-size: 20px;">
                    <span
                        class="spinner-border spinner-border-lg spinner-color"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Loading Blog Post...
                </div>
            </div>
        </div>

        <div class="row" *ngIf="responseError">
            <div class="col-lg-12 col-md-12 my-5" >
                <div class="text-center" style="font-size: 20px;">
                    <h3>{{responseError || "Error 404!"}}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
