<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="social-right" style="z-index: 1;">
               <a href="https://wa.me/+447916507777" target="_blank">
                   <img src="assets/img/wa.png" class="rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
               <a href="https://www.facebook.com/IndianAromaGantsHill/" target="_blank">
                   <img src="assets/img/fb.png" class="rounded-img" alt="INDIAN AROMA CATERERS">
               </a>
               <a href="https://www.instagram.com/indian_aroma_london/" target="_blank">
                   <img src="assets/img/inst.png" class="ml-1 rounded-img" alt="INDIAN AROMA CATERERS">
               </a>
               <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" target="_blank">
                   <img src="assets/img/trip.png"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
               </a>
               <a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" target="_blank">
                   <img src="assets/img/youtube.png"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
               </a>
               <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" target="_blank">
                   <img src="assets/img/googleplus.png"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
               </a>
               <a href="https://twitter.com/IndianAroma_LDN" target="_blank">
                   <img src="assets/img/twiter.png"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
               </a>
               <a href="https://www.pinterest.co.uk/indianaromalondon/" target="_blank">
                   <img src="assets/img/printest.svg"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
               </a>
               <a href="https://www.linkedin.com/company/indian-aroma-cuisine-limited/" target="_blank">
                   <img src="assets/img/linkdin.png"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
               </a>
           </div>

       </div>
        <div class="footer-bottom-area mt-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">

                    <p style="float: left;font-size: xx-small;">
                        Copyright @2024 All rights reserved by <a href="https://www.indianaroma.co.uk/" target="_blank">Indian Aroma Cuisine Limited</a>

                        | Site Developed by <a target="_blank" href="https://www.radsuntech.com/">RAD SUN TECHNOLOGY LIMITED</a></p>
                </div>
                <div class="col-lg-6 col-md-6" style="float: left;font-size: xx-small;">
                    <ul>
                        <li><a routerLink="/cookie-policy" >Cookie Policy</a></li>
                        <li><a routerLink="/privacy-policy" >Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines" >
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
