<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Film Crew Catering</li>
            </ul>
            <h2>Film Crew Catering</h2>
        </div>
    </div>

</div>

<div class="profile-area pt-5 pb-70">
    <div class="container-fluid w-equal">
        <div class="profile-box">
            <div class="row">
                <div class="col-lg-4 col-md-2 col-sm-6">
                    <!-- <div class="image pt-5">
                        <img src="assets/img/catering-services/film-catering.png" width="490" height="323" alt="image">
                    </div> -->
                    <div class="single-gallery-item">

                        <div class="about-video-box">
                            <div class="image">
                                <img src="../../../../assets/img/catering-services/film-catering.png" alt="image">
                                <a href="https://iacaterersbucket-prod.s3.eu-west-2.amazonaws.com/filmcrewcatering/film+catering+video.mp4" target="_blank" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-10 col-sm-6">
                    <div class="content">
                        <h3>CATERERS FOR Film Crew</h3>
                        <span class="sub-title">Film Set Catering</span>
<p>Have you ever wondered who feeds your favourite movie stars while they're on set? We are one of the most well-known firms in Catering. We can accommodate all of your needs, big or small, by serving breakfast, lunch, and afternoon tea.
</p>

<p>Everything we serve is top-notch food that we create on-site. We make it happen no matter where you are. Our catering team has worked with some of the most well-known movie actors.
    </p>

    <p>    Our menus are updated on a daily basis. There isn't a rotating menu here. We are continually introducing new meals in addition to old favourites.
    </p>

    <p>    Please contact us if your production requires a specific service, location, or time. We would be delighted to discuss your project.
    </p>


                        <ul class="info">
                            <li>



                                <p>Phone:<a href="#">020 8550 7777</a> <br/>Mobile:<a href="tel:+447916507777">079 1650 7777</a></p>




                            </li>
                            <li><span>Email:</span><a href="mailto:contact@indianaromacaterers.co.uk">contact@indianaromacaterers.co.uk</a></li>
                        </ul>
                        <ul class="social-link" >
                            <li><a href="https://www.facebook.com/IndianAromaGantsHill/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/indian_aroma_london/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" class="d-block" target="_blank"><i class="bx bxl-youtube"></i></a></li>

                            <li><a href="https://wa.me/+447916507777" class="d-block" target="_blank"><i class="bx bxl-whatsapp"></i></a></li>


                            <li><a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" class="d-block" target="_blank"><i class="bx bxl-google"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" class="d-block" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                            <li><a href="https://twitter.com/IndianAroma_LDN" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="https://www.pinterest.co.uk/indianaromalondon/" class="d-block" target="_blank"><i class="bx bxl-pinterest"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/indian-aroma-cuisine-limited/" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" class="d-block" target="_blank"><i class="fa fa-tripadvisor"></i></a></li>
                        </ul>
                    </div>
                    <app-services-buttons-block></app-services-buttons-block>
                </div>
            </div>
        </div>
      <!--   <div class="profile-courses-quizzes">
            <ngx-tabset>
                <ngx-tab tabTitle="Gallery">
                    <ngx-tabset>
                        <ngx-tab tabTitle="London">
                            <ngx-tabset>
                                <ngx-tab tabTitle="All">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img2.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow"><i class="flaticon-heart"></i></div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/pot.png" class="rounded-circle" alt="image">
                                                        <span>Lunch Menu</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img2.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                                        <span>Sarah Taylor</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img3.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                                        <span>David Warner</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img6.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Machine Learning Practical:Real world Projects in Healthcare</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img7.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Teach Your Kids to Code: Learn Python Programming at Any Age</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img8.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Data Science 2020 : Complete Data Science & Machine Learning</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="pagination-area text-center">
                                                <a routerLink="/profile" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                                                <span class="page-numbers current" aria-current="page">1</span>
                                                <a routerLink="/profile" class="page-numbers">2</a>
                                                <a routerLink="/profile" class="page-numbers">3</a>
                                                <a routerLink="/profile" class="page-numbers">4</a>
                                                <a routerLink="/profile" class="page-numbers">5</a>
                                                <a routerLink="/profile" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </ngx-tab>
                                <ngx-tab tabTitle="Pictures">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img1.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user1.png" class="rounded-circle" alt="image">
                                                        <span>Alex Morgan</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img2.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                                        <span>Sarah Taylor</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img3.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                                        <span>David Warner</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img6.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Machine Learning Practical:Real world Projects in Healthcare</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img7.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Teach Your Kids to Code: Learn Python Programming at Any Age</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img8.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Data Science 2020 : Complete Data Science & Machine Learning</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ngx-tab>
                                <ngx-tab tabTitle="Videos">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img6.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Machine Learning Practical:Real world Projects in Healthcare</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img7.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Teach Your Kids to Code: Learn Python Programming at Any Age</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img8.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Data Science 2020 : Complete Data Science & Machine Learning</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ngx-tab>
                            </ngx-tabset>
                        </ngx-tab>
                        <ngx-tab tabTitle="Outside London">
                            <ngx-tabset>
                                <ngx-tab tabTitle="All">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img6.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Machine Learning Practical:Real world Projects in Healthcare</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img7.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Teach Your Kids to Code: Learn Python Programming at Any Age</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img8.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Data Science 2020 : Complete Data Science & Machine Learning</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img1.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user1.png" class="rounded-circle" alt="image">
                                                        <span>Alex Morgan</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img2.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                                        <span>Sarah Taylor</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img3.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                                        <span>David Warner</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="pagination-area text-center">
                                                <a routerLink="/profile" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                                                <span class="page-numbers current" aria-current="page">1</span>
                                                <a routerLink="/profile" class="page-numbers">2</a>
                                                <a routerLink="/profile" class="page-numbers">3</a>
                                                <a routerLink="/profile" class="page-numbers">4</a>
                                                <a routerLink="/profile" class="page-numbers">5</a>
                                                <a routerLink="/profile" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </ngx-tab>
                                <ngx-tab tabTitle="Published">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img1.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user1.png" class="rounded-circle" alt="image">
                                                        <span>Alex Morgan</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img2.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                                        <span>Sarah Taylor</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img3.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                                        <span>David Warner</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img6.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Machine Learning Practical:Real world Projects in Healthcare</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img7.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Teach Your Kids to Code: Learn Python Programming at Any Age</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img8.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Data Science 2020 : Complete Data Science & Machine Learning</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ngx-tab>
                                <ngx-tab tabTitle="Pending">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img6.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Machine Learning Practical:Real world Projects in Healthcare</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img7.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Teach Your Kids to Code: Learn Python Programming at Any Age</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img8.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Data Science 2020 : Complete Data Science & Machine Learning</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ngx-tab>
                            </ngx-tabset>
                        </ngx-tab>
                    </ngx-tabset>
                </ngx-tab>
                <ngx-tab tabTitle="Menus">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Combo 1">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Starters</th>
                                            <th>Mains</th>
                                            <th>Accompaniments</th>
                                            <th>Desserts</th>
                                            <th>Beverages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Introduction to  Python for Beginners</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 20, 2020</td>
                                            <td>25% <span class="progress">In Progress</span></td>
                                            <td>15:30</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Python for Data Science and Machine Learning Bootcamp</a></td>
                                            <td>Lesson 2 Term Test</td>
                                            <td>May 21, 2020</td>
                                            <td>0% <span class="progress pending">Pending</span></td>
                                            <td>20:20</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">The Modern Python 3 Bootcamp</a></td>
                                            <td>Lesson 5 Term Test</td>
                                            <td>May 22, 2020</td>
                                            <td>100% <span class="progress completed">Completed</span></td>
                                            <td>10:10</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">REST APIs with Flask and Python</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 23, 2020</td>
                                            <td>30% <span class="progress">In Progress</span></td>
                                            <td>15:30</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Reinforcement Learning in Python</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 24, 2020</td>
                                            <td>35% <span class="progress failed">Failed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Combo 2">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Course</th>
                                            <th>Quiz</th>
                                            <th>Date</th>
                                            <th>Progress</th>
                                            <th>Interval</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Introduction to  Python for Beginners</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 20, 2020</td>
                                            <td>100% <span class="progress completed">Completed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Python for Data Science and Machine Learning Bootcamp</a></td>
                                            <td>Lesson 2 Term Test</td>
                                            <td>May 21, 2020</td>
                                            <td>100% <span class="progress completed">Completed</span></td>
                                            <td>20:20</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">The Modern Python 3 Bootcamp</a></td>
                                            <td>Lesson 5 Term Test</td>
                                            <td>May 22, 2020</td>
                                            <td>100% <span class="progress completed">Completed</span></td>
                                            <td>10:10</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">REST APIs with Flask and Python</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 23, 2020</td>
                                            <td>100% <span class="progress completed">Completed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Reinforcement Learning in Python</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 24, 2020</td>
                                            <td>100% <span class="progress completed">Completed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Combo 3">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Course</th>
                                            <th>Quiz</th>
                                            <th>Date</th>
                                            <th>Progress</th>
                                            <th>Interval</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Introduction to  Python for Beginners</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 20, 2020</td>
                                            <td>95% <span class="progress passed">Passed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Python for Data Science and Machine Learning Bootcamp</a></td>
                                            <td>Lesson 2 Term Test</td>
                                            <td>May 21, 2020</td>
                                            <td>90% <span class="progress passed">Passed</span></td>
                                            <td>20:20</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">The Modern Python 3 Bootcamp</a></td>
                                            <td>Lesson 5 Term Test</td>
                                            <td>May 22, 2020</td>
                                            <td>92% <span class="progress passed">Passed</span></td>
                                            <td>10:10</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">REST APIs with Flask and Python</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 23, 2020</td>
                                            <td>98% <span class="progress passed">Passed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Reinforcement Learning in Python</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 24, 2020</td>
                                            <td>99% <span class="progress passed">Passed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Combo 4">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Course</th>
                                            <th>Quiz</th>
                                            <th>Date</th>
                                            <th>Progress</th>
                                            <th>Interval</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Introduction to  Python for Beginners</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 20, 2020</td>
                                            <td>0% <span class="progress failed">Failed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Python for Data Science and Machine Learning Bootcamp</a></td>
                                            <td>Lesson 2 Term Test</td>
                                            <td>May 21, 2020</td>
                                            <td>5% <span class="progress failed">Failed</span></td>
                                            <td>20:20</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">The Modern Python 3 Bootcamp</a></td>
                                            <td>Lesson 5 Term Test</td>
                                            <td>May 22, 2020</td>
                                            <td>7% <span class="progress failed">Failed</span></td>
                                            <td>10:10</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">REST APIs with Flask and Python</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 23, 2020</td>
                                            <td>3% <span class="progress failed">Failed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                        <tr>
                                            <td><a routerLink="/single-courses-1">Reinforcement Learning in Python</a></td>
                                            <td>Lesson 1 Term Test</td>
                                            <td>May 24, 2020</td>
                                            <td>1% <span class="progress failed">Failed</span></td>
                                            <td>15:30</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </ngx-tab>
            </ngx-tabset>
        </div> -->
    </div>
</div>
