<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Gallery</li>
            </ul>
            <h2>Gallery</h2>
        </div>
    </div>

</div>

<div class="gallery-area pt-100 pb-70">
    <div class="container">


        <div class="events-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="Pictures">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let image of data.body; let i=index;">
                            <div class="single-gallery-item">
                                <img [src]="image" [lazyLoad] ="image" />
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Videos">


                    <div class="row">

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="../../../../assets/img/catering-services/Frame 7.png" alt="image">
                                    </div>
                                    <a href="https://www.youtube.com/watch?v=7zqhZUPtcLI" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="../../../../assets/img/catering-services/Frame 8.png" alt="image">
                                    </div>
                                    <a href="https://www.youtube.com/watch?v=ZcdvFSGuXuM" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>




                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="../../../../assets/img/catering-services/Frame 9.png" alt="image">
                                    </div>

                                    <a href="https://www.youtube.com/watch?v=1Czqh2HADmw" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>





                    </div>

                    <div class="row">

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-10.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/QBCj36H1W6Q" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="../../../../assets/img/catering-services/film-catering.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/XOZkiIw0ETA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>




                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="../../../../assets/img/catering-services/fgv2.jpg" alt="image">
                                    </div>

                                    <a href="https://www.youtube.com/watch?v=GZrwWsVYXiY" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>





                    </div>
                    <div class="row">


                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-4.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/Ea-8xXPbOX0" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-6.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/cdu3A7AEBN8" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>









                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-3.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/NeuFbLdanVk" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>






                    </div>



                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-7.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/Aya-zxM66BE" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>





                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-2.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/MmWMXcSu01A" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-9.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/XBwYDFuNM8U" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>



                    </div>

                    <div class="row">

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-66.png" alt="image">
                                    </div>
                                    <a href=" https://youtu.be/YxBt3fHD1Pg" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-67.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/AvbKg86T6HA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">

                                <div class="about-video-box">
                                    <div class="image">
                                        <img src="assets/img/gallery/videos/iac-13.png" alt="image">
                                    </div>
                                    <a href="https://youtu.be/2SMRxgwkjQg" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                                </div>
                            </div>
                        </div>





                    </div>



                </ngx-tab>
            </ngx-tabset>
        </div>


    </div>
</div>
