<!-- <div class="search_tab">
    <div class="form-outline mb-4">
        <input type="search" [(ngModel)]="searchText" class="form-control" id="datatable-search-input" placeholder="Search by blog title or keyword">
    </div>
</div> -->
<div class="page-title-area pb-0">
  <div class="container">
    <div class="page-title-content">
      <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
            </ul> -->
            <h2>{{pageData?.title}}</h2>
      <div class="section-title">
        <span class="sub-title"></span>
        <p >{{pageData?.description}}</p>
      </div>
    </div>
  </div>
</div>

<div class="blog-area ptb-100">
  <div class="container">
    <div class="row">
      <div
        [style.display]="pageData?.posts?.length == 0 || isLoading ? 'none' : ''"
        class="col-lg-4 col-md-6"
        *ngFor="let post of pageData?.posts"
        (click)="openPostDetails(post?.url)"
      >
        <div class="single-blog-post-box">
          <div class="post-image">
            <a class="d-block">
              <img [src]="post.imageUrl" [alt]="post.imageAlt" />
            </a>
          </div>
          <div class="post-content">
            <a class="category">{{ post?.category?.name }}</a>
            <h3>
              <a>{{ post?.title.slice(0, 50) + "..." }}</a>
            </h3>
            <ul
              class="post-content-footer d-flex justify-content-between align-items-center"
            >
              <li>
                <div class="post-author d-flex align-items-center">
                  <img
                    [src]="post?.publishedBy?.image"
                    class="rounded-circle"
                    style="
                      height: 30px;
                      object-fit: cover;
                      margin-bottom: 4px;
                    "
                    alt="image"
                  />
                  <span>{{ "Admin" }}</span>
                </div>
              </li>
              <li>
                <i class="flaticon-calendar"></i
                >{{ post?.createdAt | date : "mediumDate" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 my-5" *ngIf="isLoading">
        <div
          class="text-center d-flex justify-content-center align-items-center flex-column gap-3"
          style="font-size: 20px"
        >
          <span
            class="spinner-border spinner-border-lg spinner-color"
            role="status"
            aria-hidden="true"
          ></span>
          Loading Blog Posts...
        </div>
      </div>
      <div
        class="col-lg-12 col-md-12 my-5"
        *ngIf="pageData?.posts?.length == 0 && !isLoading"
      >
        <div class="text-center">
          <h3>No Blog Posts Found</h3>
        </div>
      </div>
    </div>
  </div>
</div>
