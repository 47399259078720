<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Sports Events</li>
            </ul>
            <h2>Sports Events</h2>
        </div>
    </div>

</div>

<div class="profile-area pt-5 pb-70">
    <div class="container-fluid w-equal">
        <div class="profile-box">
            <div class="row">
                <!-- <div class="col-lg-4 col-md-2 col-sm-6">
                    <div class="image pt-5">
                        <img src="assets/img/catering-services/sports.png" class="image-size" alt="image">
                    </div>
                </div> -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-gallery-item">

                        <div class="single-gallery-item">

                            <div class="about-video-box">
                                <div class="image">
                                    <img src="../../../../assets/img/catering-services/livedosaimageV2.png" alt="image">
                                    <a href="https://youtu.be/RySOpefXxGE" target="_blank" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-10 col-sm-6">
                    <div class="content">
                        <h3>CATERERS FOR Sports Events</h3>
                        <span class="sub-title">Sports Events</span>
                        <p>As event caterers, we recognise that each event is unique, which is what makes them unique. Our private event catering services are completely customizable from beginning to end, and we are fully equipped to create your dream menu!</p>
                        <p>We support and cater for various sporting clubs such as cricket, rugby, football etc. We'll create a custom catering menu to fit your specific interests and budget for your next private event. Offer your visitors quality, and a unique experience.
                        </p>
                        <p>We take additional care in preparing our meals, and it shows in every taste. Everything we bring into our kitchen supports the local economy, and meets our stringent growing requirements.
                        </p>
                        <p>Are you seeking for more than just box lunches when it comes to lunch catering? We take pleasure in being a catering company that offers a one-of-a-kind menu. Take a look at some of our catering menus to get a sense of what we can offer.</p>
                        <ul class="info">
                            <li>
                                <p>Phone:<a href="#">020 8550 7777</a> <br/>Mobile:<a href="tel:+447916507777">079 1650 7777</a></p>
                            </li>
                            <li><span>Email:</span><a href="mailto:contact@indianaromacaterers.co.uk">contact@indianaromacaterers.co.uk</a></li>
                        </ul>
                        <ul class="social-link" >
                            <li><a href="https://www.facebook.com/IndianAromaGantsHill/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/indian_aroma_london/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" class="d-block" target="_blank"><i class="bx bxl-youtube"></i></a></li>

                            <li><a href="https://wa.me/+447916507777" class="d-block" target="_blank"><i class="bx bxl-whatsapp"></i></a></li>


                            <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-google-plus'></i></a></li> -->
                            <li><a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" class="d-block" target="_blank"><i class="bx bxl-google"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" class="d-block" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                            <li><a href="https://twitter.com/IndianAroma_LDN" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="https://www.pinterest.co.uk/indianaromalondon/" class="d-block" target="_blank"><i class="bx bxl-pinterest"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/indian-aroma-cuisine-limited/" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" class="d-block" target="_blank"><i class="fa fa-tripadvisor"></i></a></li>

                        </ul>
                    </div>
                    <app-services-buttons-block></app-services-buttons-block>
                </div>
            </div>
        </div>
    </div>
</div>

