import { Component, OnInit } from '@angular/core';
import { ContactUsService } from './contact-us.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    reactiveForm: FormGroup;
    submitted :boolean;
    phn=false;
    nameinvalidError: boolean = false;
    emailinvalid: boolean = false;
    phoneinvalid: boolean = false;
    messageinvalid: boolean = false;
    titleinvalidError: boolean  = false;
    title = null;
    emailPattern = "^[a-zA-Z0-9_\\+-]+(\\.[a-zA-Z0-9_\\+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]{2,4})$";

    constructor(private contactUsService : ContactUsService,
                private http: HttpClient,
                private fb: FormBuilder,
                private router: Router) { }

    ngOnInit(): void {
        this.reactiveForm = this.fb.group({
            title: [null,Validators.required],
            name:['',Validators.required],
            email:['',[Validators.required,Validators.email,Validators.pattern(this.emailPattern)]],
            phone:['',Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
            message:['',Validators.required]
        });
    }

    bgImage = [
        {
            img: 'assets/img/register-shape.jpg'
        }
    ]

    get f() { return this.reactiveForm.controls; }


    nameinvalid() {
        if(this.reactiveForm.get('name').value == "" || this.reactiveForm.get('name').value == undefined){
            this.nameinvalidError = true;

        } else {
            this.nameinvalidError = false;
        }
    }

    emailinvalidError() {

        if(this.reactiveForm.get('email').value == "" || this.reactiveForm.get('email').value == undefined){
            this.emailinvalid = true;
            this.submitted = true;
        } else {
            this.emailinvalid = false;
            this.submitted = true;
        }
    }

    phoneinvalidError() {
        if(this.reactiveForm.get('phone').value == "" || this.reactiveForm.get('phone').value == undefined){
            this.phoneinvalid = true;
            this.phn=true;
        } else {
            this.phoneinvalid = false;
            this.phn=true;
        }
    }

    messageinvalidError() {
        if(this.reactiveForm.get('message').value == "" || this.reactiveForm.get('message').value == undefined){
            this.messageinvalid = true;
        } else {
            this.messageinvalid = false;
        }
    }

    titleinvalid() {
        if(this.reactiveForm.get('title').value == "" || this.reactiveForm.get('title').value == undefined){
            this.titleinvalidError = true;
        } else {
            this.titleinvalidError = false;
        }
    }

    dataReset() {
        this.nameinvalidError = false;
        this.emailinvalid = false;
        this.phoneinvalid = false;
        this.messageinvalid = false;
        this.reactiveForm.reset();
    }

    submitData(){
        ;
        if(this.reactiveForm.invalid) {
            this.nameinvalid();
            this.emailinvalidError();
            this.phoneinvalidError();
            this.titleinvalid();
            this.messageinvalidError();
            
            // this.router.navigateByUrl('/error');
        } else {
            ;
            this.submitted=false;
            ;
            this.contactUsService.getContactusDetail(this.reactiveForm.value);
            this.router.navigateByUrl('/thank_you');
        }
    }
    separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});
    get pf() { return this.phoneForm.controls; }
	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}

}
