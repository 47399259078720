import { HttpClient } from '@angular/common/http';
import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventEnquiryService } from './event-enquiry.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-event-enquiry',
  templateUrl: './event-enquiry.component.html',
  styleUrls: ['./event-enquiry.component.scss']
})
export class EventEnquiryComponent implements OnInit {

    otherData:boolean = false;
    reactiveForm: FormGroup;
    nameinvalidError: boolean = false;
    emailinvalid: boolean = false;
    phoneinvalid: boolean = false;
    messageinvalid: boolean = false;
    titleinvalidError: boolean  = false;
    serviceTypeError: boolean = false;
    title = null;
    servicetype = null;
    maxBirthDate: string;
    emailPattern = "^[a-zA-Z0-9_\\+-]+(\\.[a-zA-Z0-9_\\+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]{2,4})$";
    submitted: boolean;
    phn = false;
    now: string;


    constructor(private eventService: EventEnquiryService,
                private fb: FormBuilder,
                private http: HttpClient,
                private router: Router) { }

    ngOnInit(): void {
        let auxDate = this.substractYearsToDate(new Date(), 100);
        this.maxBirthDate = this.getDateFormateForSearch(auxDate);
        const datePipe = new DatePipe('en-Us');
        this.now = datePipe.transform(new Date(), 'yyyy-MM-dd');

        this.reactiveForm = this.fb.group({
            title: [null,Validators.required],
            name:['',Validators.required],
            email:['',[Validators.required,Validators.email,Validators.pattern(this.emailPattern)]],
            phone:['',Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
            servicetype:['',Validators.required],
            othercatering:[''],
            occasiondescription:[''],
            eventdate:[''],
            eventstarttime:[''],
            numberofguests:[''],
            venueinmind:[''],
            message:['',Validators.required]
        });
    }

    bgImage = [
        {
            img: 'assets/img/register-shape.jpg'
        }
    ]
    get f() { return this.reactiveForm.controls; }
    nameinvalid() {
        if(this.reactiveForm.get('name').value == "" || this.reactiveForm.get('name').value == undefined){
            this.nameinvalidError = true;
            this.submitted = true;
        } else {
            this.nameinvalidError = false;
            this.submitted = true;
        }
    }

    emailinvalidError() {
        // const data1 = this.reactiveForm.get('email').value;
        // this.http.post('https://ihcv59cjhk.execute-api.eu-west-2.amazonaws.com/dev/verifyemail',{email:data1}).subscribe(res=>{
        //     const data = res['valid_email'];

        //     if(data == "no") {
        //         this.emailinvalid = true;
        //     } else {
        //         this.emailinvalid = false;
        //     }
        // })

        if(this.reactiveForm.get('email').value == "" || this.reactiveForm.get('email').value == undefined){
            this.emailinvalid = true;
            this.submitted = true;
        } else {
            this.emailinvalid = false;
            this.submitted = true;
        }
    }

    phoneinvalidError() {
        if(this.reactiveForm.get('phone').value == "" || this.reactiveForm.get('phone').value == undefined){
            this.phoneinvalid = true;
            this.phn=true;
        } else {
            this.phoneinvalid = false;
            this.phn=true;
        }
    }

    messageinvalidError() {
        if(this.reactiveForm.get('message').value == "" || this.reactiveForm.get('message').value == undefined){
            this.messageinvalid = true;
        } else {
            this.messageinvalid = false;
        }
    }

    titleinvalid() {
        if(this.reactiveForm.get('title').value == "" || this.reactiveForm.get('title').value == undefined){
            this.titleinvalidError = true;
        } else {
            this.titleinvalidError = false;
        }
    }

    serviceTypeinvalid() {
        if(this.reactiveForm.get('servicetype').value == "" || this.reactiveForm.get('servicetype').value == undefined || this.reactiveForm.get('servicetype').value == null ){
            this.serviceTypeError = true;
        } else {
            this.serviceTypeError = false;
        }
    }

    dataReset() {
        this.reactiveForm.reset();
    }

    substractYearsToDate(auxDate: Date, years: number): Date {
        auxDate.setFullYear(auxDate.getFullYear() + years);
        return auxDate;
      }

      getDateFormateForSearch(date: Date): string {
        let year = date.toLocaleDateString('es', { year: 'numeric' });
        let month = date.toLocaleDateString('es', { month: '2-digit' });
        let day = date.toLocaleDateString('es', { day: '2-digit' });
        return `${year}-${month}-${day}`;
      }

    submitData(){
        if(this.reactiveForm.invalid) {
            this.nameinvalid();
            this.emailinvalidError();
            this.phoneinvalidError();
            this.messageinvalidError();
            this.titleinvalid();
            this.serviceTypeinvalid();
            
            // this.router.navigateByUrl('/error');
        } else {
            ;
            this.submitted=false;
            this.eventService.getEventData(this.reactiveForm.value);
            this.router.navigateByUrl('/thank_you');
        }
    }

    onChange(deviceValue) {
        if(deviceValue == "Other") {
            this.otherData =true;
        } else {
            this.otherData = false;
        }
    }
    separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});
    get pf() { return this.phoneForm.controls; }
	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}
}
