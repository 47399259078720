import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
const Base_URL = 'https://2a3sde3.blog-indianaroma.co.uk';
// const Base_URL = 'http://localhost:5050';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public UserDetails = new BehaviorSubject<any>(null);
  accessToken = '';

  constructor(private http: HttpClient) {

  }

  private getHeaders():HttpHeaders {
    return new HttpHeaders({"Authorization":"Bearer "+this.accessToken})
   }

   public  getPosts(page:number,searchText?:string): Observable<any> {
    return this.http.get(`${Base_URL}/posts/blog?page=${page}${searchText ? `&searchText=${searchText}` : ''}`)
  }

  public  getHeaderContent(): Observable<any> {
    return this.http.get(`${Base_URL}/pages/header`)
  }

  public async getPostById(id:any): Promise<any> {
    return await this.http.get(`${Base_URL}/post/blog/${id}`).toPromise()
  }

  public async getPageById(id:any): Promise<any> {
    return await this.http.get(`${Base_URL}/page/${id}`).toPromise()
  }
}
