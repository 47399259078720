import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './Unsubscribe-Newsletter.component.html',
  styleUrls: ['./Unsubscribe-Newsletter.component.scss']
})
export class UnsubscribeNewsLetterComponent implements OnInit {
  id: string;


  constructor(
    private http :HttpClient,
    private route:ActivatedRoute) {
        this.route.queryParams.subscribe(param =>{
            this.id=param['id'];
        })
    }
  ngOnInit(): void {
    this.http.get('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/newsletter/ses/unsubscribe?id='+this.id).subscribe(res =>{
    ;
    })
  }

}
