import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

    constructor(private http: HttpClient) { }

    getContactusDetail(x: any) {
      const contactusdata = this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/contactus/dynamodb',x)
      const emailVerification = this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/contactus/thankyouemail',x)

      forkJoin([contactusdata,emailVerification]).subscribe(res =>{
          return res;
      })
    }
}
