import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-distance-banner',
    templateUrl: './distance-banner.component.html',
    styleUrls: ['./distance-banner.component.scss']
})
export class DistanceBannerComponent implements OnInit {
    data: any = [];
    sampleImage: boolean = true;

    constructor(private http: HttpClient) {}

    ngOnInit(): void {

        this.http.get(`https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/frontend_assets/get?q=frontend_assets`).subscribe(res=>{
            if(res){
                this.data = res;
                this.sampleImage = false;
            }
            else {
                this.sampleImage = true;
            }

        });
    }

    trackByFn(index, item) {
        return index;
    }

    gymBannerSlides: OwlOptions = {
        items: 1,
        nav: true,
        loop: true,
        dots: false,
        autoplay: true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplayHoverPause: true,
        navText: [
            "<i class='flaticon-chevron'></i>",
            "<i class='flaticon-right-arrow'></i>"
        ],
        responsive: {
            0: {
                autoHeight: true
            },
            576: {
                autoHeight: false
            },
            768: {
                autoHeight: false
            },
            992: {
                autoHeight: false
            }
        }
    }

    bgImage = [
        {
            img: 'assets/img/gym-banner-bg.jpg'
        }
    ]

}
