import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { FeedbackService } from './feedback.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
    feedBackData: Object;
    currentRate = 2;
    selectedCategory: string = 'getreviews';
    url : string = 'getreviews'
    baseurl ='https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/reviews/'
    selectitem =[
        {
            "name": 'all',
            "link" : 'getreviews'
        },
        {
            "name": 'Family Gathering',
            "link" : 'familygathering-reviews'
        },
        {
            "name" : 'live dosa',
            "link" : 'livedosastation-review'
        },
        {
        "name": 'festive & social',
        "link" : 'festival-and-social-events-reviews'
    }
    ]

    selectListValue: FormControl = new FormControl( 'getreviews');
  subscription: Subscription;

    constructor(private feedbackDataService:FeedbackService,
        private http: HttpClient) { }

    ngOnInit(): void {
        this.getFeedbackData().subscribe(results =>
            this.feedBackData = results
        );
        this.subscription = this.selectListValue.valueChanges
        .subscribe(changes => this.http.get(`https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/reviews/${changes}`).subscribe(results =>
        this.feedBackData = results
        ));
    }

    getFeedbackData(){
        const apiUrl = `https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/reviews/${this.selectedCategory}`;
        
        return this.http.get(apiUrl);
      }

    feedbackSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    }

}
