import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    data: any = [];

    ngOnInit(): void {
        this.http.get(`https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/frontend_assets/get?q=gallery`).subscribe(res=>{
            this.data =res;
        })
    }

    public _album  = [];
    public _album_videos  = [];

    constructor(public _lightbox: Lightbox, private router: Router,private http: HttpClient) {
        for (let i = 1; i <= 65; i++) {
            const src = 'assets/img/gallery/iac-' + i + '.png';
            const caption = 'Image ' + i;
            const thumb = 'assets/img/gallery/iac-' + i + '.png';
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this._album.push(album);
        }

        for (let i = 1; i <= 5; i++) {
            const src = 'https://master.d3rohc12j4uf84.amplifyapp.com/assets/img/gallery/videos/iac-' + i + '.mp4';
            const caption = 'Image ' + i;
            const thumb = 'https://master.d3rohc12j4uf84.amplifyapp.com/assets/img/gallery/videos/iac-' + i + '.mp4';
            const album_video = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this._album_videos.push(album_video);
        }

    }

    open(index: number): void {
        // open lightbox
        this._lightbox.open(this._album, index);
    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }

}

