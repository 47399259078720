import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'view-page',
  templateUrl: './view-page.component.html',
  styleUrls: ['./view-page.component.scss']
})
export class ViewPageComponent implements OnInit,AfterViewInit,OnDestroy {

  pageData : any = {};
  activePageId :string = '';
    isLoading = false;
    isError = false;
  constructor(private router : Router, private data:DataService, private activeRoute:ActivatedRoute) {
    activeRoute.params.subscribe(params=>{
      this.activePageId = null;
      if(params?.page){
          this.activePageId = params?.page
      }
      this.getPageById()
  })

   }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
     
  }

  ngOnDestroy(): void {
  }

  getPageById(){
    this.isLoading = true;
    this.data.getPageById(this.activePageId).then((res:any)=>{
        this.pageData = res;

        this.isLoading = false;
        }).catch(err=>{

        this.isLoading = false;
        this.isError = true;
        })
  }

  openPostDetails(postUrl:any){
    this.router.navigate([`/blog-details/${postUrl}`])
  }
}
