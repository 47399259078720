import { filter } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MenuListServiceService } from '../menu-list-service.service';

@Component({
  selector: 'app-canapes-menu',
  templateUrl: './canapes-menu.component.html',
  styleUrls: ['./canapes-menu.component.scss'],
  providers:[MenuListServiceService]
})
export class CanapesMenuComponent implements OnInit {
    data: any= [];
    desserts: any;
    extrasList: any = [];
    mainsList: any = [];
    soupsList: any;
    startrsList: any = [];
    list:any =[];
    groupArr: any;
    regional_non_veg: any;
    main_curries: any;
    mains_rice: any;
    regional_veg: any;
    biriyani_dishes: any;
    south_india_dish: any;
    mains_noodles: any;
    bread_basket: any;
    family_pack: any;
    kids_meal: any;
    mains_fry: any;
    classic_starters: any;
    soup: any;
    tandoori_starts: any;
    street_tandoori_starts: any;
    extra_kids: any;
    extra_pickle: any;
    extra: any;
    isSticky: boolean = false;
    isReadMore = true

  showText() {
     this.isReadMore = !this.isReadMore
  }

    @HostListener('window:scroll', ['$event'])

    checkScroll() {
      this.isSticky = window.pageYOffset >= 250;
    }
  constructor(private http: HttpClient,
              private menuListServiceService:MenuListServiceService) {}

  ngOnInit(): void {
      this.menuListServiceService.getmenudata().subscribe(res =>{
          this.list = res;
          
          this.regional_non_veg = this.list.filter(x => x.menu === "Regional_Non_Veg");
          this.main_curries = this.list.filter(x => x.menu === "Mains_Curries");
          this.mains_rice = this.list.filter(x => x.menu === "Mains_Rice");
          this.regional_veg = this.list.filter(x => x.menu === "Regional_Veg");
          this.biriyani_dishes= this.list.filter(x => x.menu === "Biryani_Dishes");
          this.south_india_dish = this.list.filter(x => x.menu === "South_Indian_Dishes");
          this.mains_noodles = this.list.filter(x => x.menu === "Mains_Noodles");
          this.bread_basket = this.list.filter(x => x.menu === "Bread_Basket");
          this.family_pack = this.list.filter(x => x.menu === "Family_Pack_Biryani");
          this.kids_meal = this.list.filter(x => x.menu === "Kids_Meal");
          this.mains_fry = this.list.filter(x => x.menu === "Mains_Fry");
          this.groupArr = this.list.reduce((r,{menu})=>{
            if(!r.some(o=>o.menu==menu)){
              r.push({menu,groupItem:this.list.filter(v=>v.menu==menu)});
        }
        return r;
        },[]);
      })
      this.getStarters();
      this.getDesserts();
      this.getmenu();
      this.getSoups();
  }

  getStarters(){
   this.menuListServiceService.getStartersmenu().subscribe(res=>{
    this.list = res;
    this.startrsList = this.list.filter(x => x.menu === "Starters");
    this.classic_starters = this.list.filter(x => x.menu === "Classic_Starters");
    this.tandoori_starts = this.list.filter(x => x.menu === "Tandoori_Starters");
    this.street_tandoori_starts = this.list.filter(x => x.menu === "Street_Food_Starters");
   })
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
    }

  getDesserts(){
    this.menuListServiceService.getdesserts().subscribe(res=>{
        this.list = res;
        this.desserts = this.list.filter(x => x.menu === "Desserts");
    })
  }

  getmenu() {
    this.menuListServiceService.getExtraList().subscribe(res=>{
        this.list = res;
        this.extra_kids = this.list.filter(x => x.menu === "Extras_Kids");
        this.extra_pickle = this.list.filter(x => x.menu === "Extras_Pickles");
        this.extra = this.list.filter(x => x.menu === "Extras");
    })
  }

  getSoups(){
    this.menuListServiceService.getSoupList().subscribe(res=>{
        this.list = res;
        this.soupsList = this.list.filter(x => x.menu === "Soup");
    })
  }


}
