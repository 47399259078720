<!-- <div class="search_tab">
    <div class="form-outline mb-4">
        <input type="search" [(ngModel)]="searchText" class="form-control" id="datatable-search-input" placeholder="Search by blog title or keyword">
    </div>
</div> -->
<div class="page-title-area pb-0">
  <div class="container">
    <div class="page-title-content">
      <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
            </ul> -->
      <h2>Indian Aroma Blog</h2>
      <div class="section-title">
        <span class="sub-title"></span>
        <p>
            Welcome to the Indian Aroma Restaurant and Catering Blog.  
            We serve truly authentic Indian food for all occasions. Our blog articles discuss restaurant and catering-related events.
        </p>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <div class="search-box m-0" style="top: 25px">
          <input
            type="text"
            class="input-search"
            placeholder="Search Blog Articles"
            [(ngModel)]="searchText"
            (keydown)="searchNow($event)"
            id="searchInput"
          />
          
          <a href="javascript:void(0);" *ngIf="searchText" (click)="resetSearch()" class="btn-clear" >
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>
        </a>
          <button
            type="button"
            [disabled]="isLoading"
            (click)="searchBlogPosts()"
          >
            <i class="flaticon-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="blog-area ptb-100">
  <div class="container">
    <div class="row">
      <div
        [style.display]="blogPosts.length == 0 || isLoading ? 'none' : ''"
        class="col-lg-4 col-md-6"
        *ngFor="let post of blogPosts"
        (click)="openPostDetails(post?.url)"
      >
        <div class="single-blog-post-box">
          <div class="post-image">
            <a class="d-block">
              <img [src]="post.imageUrl" [alt]="post.imageAlt" />
            </a>
          </div>
          <div class="post-content">
            <a class="category">{{ post?.category?.name }}</a>
            <h3>
              <a>{{ post?.title.slice(0, 50) + "..." }}</a>
            </h3>
            <ul
              class="post-content-footer d-flex justify-content-between align-items-center"
            >
              <li>
                <div class="post-author d-flex align-items-center">
                  <img
                    [src]="post?.publishedBy?.image"
                    class="rounded-circle"
                    style="
                      height: 30px;
                      object-fit: cover;
                      margin-bottom: 4px;
                    "
                    alt="image"
                  />
                  <span>{{ "Admin" }}</span>
                </div>
              </li>
              <li>
                <i class="flaticon-calendar"></i
                >{{ post?.createdAt | date : "mediumDate" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 my-5" *ngIf="isLoading">
        <div
          class="text-center d-flex justify-content-center align-items-center flex-column gap-3"
          style="font-size: 20px"
        >
          <span
            class="spinner-border spinner-border-lg spinner-color"
            role="status"
            aria-hidden="true"
          ></span>
          Loading Blog Posts...
        </div>
      </div>
      <div
        class="col-lg-12 col-md-12 my-5"
        *ngIf="blogPosts.length == 0 && !isLoading"
      >
        <div class="text-center">
          <h3>No Blog Posts Found</h3>
        </div>
      </div>
      <div
        class="col-lg-12 col-md-12"
        [style.display]="blogPosts.length == 0 || isLoading ? 'none' : ''"
      >
        <div class="pagination-area text-center">
          <a
            class="prev page-numbers"
            [ngClass]="{
              disabled: pagination.hasPrevPage != true
            }"
            (click)="previousPage()"
            ><i class="bx bx-chevrons-left"></i
          ></a>
          <a
            class="page-numbers"
            *ngFor="let page of getPagesArray()"
            [ngClass]="{
              current: page == currentPage,
              disabled: page == currentPage
            }"
            (click)="moveToPage(page)"
            >{{ page }}</a
          >
          <a
            class="next page-numbers"
            [ngClass]="{
              disabled: pagination.hasNextPage != true
            }"
            (click)="nextPage()"
            ><i class="bx bx-chevrons-right"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
