<div class="events-area pt-70 pb-70">
    <div class="container">
        <div class="events-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="All">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/courses/img3-iac.jpg" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <a routerLink="/blog-3" class="category">  <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">Diwali Festival Buffet</a>
                                    <h3><a routerLink="/single-blog-3">Festival & Social Events</a></h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/courses/img1-iac.jpg" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <a routerLink="/blog-3" class="category">  <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">Live Station</a>
                                    <h3><a routerLink="/single-blog-3">Live Dosa Station</a></h3>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/courses/img2-iac.jpg" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <a routerLink="/blog-3" class="category">  <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">Breakfast Menu</a>
                                    <h3><a routerLink="/single-blog-3">House Warming Party</a></h3>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/courses/img4-iac.jpg" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <a routerLink="/blog-3" class="category">  <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">Lunch Menu</a>
                                    <h3><a routerLink="/single-blog-3">Film Crew Catering</a></h3>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/51.png" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <a routerLink="/blog-3" class="category">  <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">Coming Soon</a>
                                    <h3><a routerLink="/single-blog-3">Coming Soon</a></h3>

                                </div>
                            </div>
                        </div>

                       <!--  <div class="col-lg-12 col-md-12">
                            <div class="pagination-area text-center">
                                <a routerLink="/blog-3" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                                <span class="page-numbers current" aria-current="page">1</span>
                                <a routerLink="/blog-3" class="page-numbers">2</a>
                                <a routerLink="/blog-3" class="page-numbers">3</a>
                                <a routerLink="/blog-3" class="page-numbers">4</a>
                                <a routerLink="/blog-3" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                            </div>
                        </div> -->
                    </div>

                </ngx-tab>
                <ngx-tab tabTitle="Live Dosa Station">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/livedosa/Untitled-1.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/livedosa/Untitled-2.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/livedosa/Untitled-3.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/livedosa/Untitled-4.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Family Gatherings">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/42.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/43.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/44.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/50.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/53.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/54.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/57.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/552.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/bd1.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/bd2.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/bd3.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/bd4.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/familygathering/bd5.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Festival & Social Events">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/456.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/Christmas.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/community gather.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/diwali.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/image5.jpg" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/last.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/New year.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/Onam.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/pongal.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/social.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/w.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/we.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/festivalsociel/wer.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Weddings">

                </ngx-tab>

                <ngx-tab tabTitle="Film Crew Catering">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/courses/img4-iac.jpg" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <a routerLink="/blog-3" class="category">Lunch Menu</a>
                                    <h3><a routerLink="/single-blog-3">Film Crew Catering</a></h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Corporate Events">

                </ngx-tab>

                <ngx-tab tabTitle="Sports Events">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/sports/s.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/sports/s2.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/sports/s3.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/sports/s4.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="assets/img/cservice/sports/sports.png" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Charity Fundraising Events">

                </ngx-tab>
            </ngx-tabset>
        </div>


    </div>
</div>

