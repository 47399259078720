import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { CookiePolicyComponent } from './components/pages/cookie-policy/cookie-policy.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { blogdetailsComponent } from './components/common/blog-details/blogdetails.component';
import { ViewPageComponent } from './components/common/view-page/view-page.component';

const routes: Routes = [
    {path: '', component: BlogComponent},
    {path:'blog-details/:post', component:blogdetailsComponent},
    // {path: 'catering-services', component: CateringServicesComponent},
    // {path: 'menus', component: MenusComponent},
    // {path: 'our-gallery', component: OurGalleryComponent},
    // {path: 'our-gallery', component: GalleryComponent},

    // {path: 'allergy-advice', component: AllergyAdviceComponent},
    {path: 'cookie-policy', component: CookiePolicyComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},

    // {path:'venues', component:TeacherComponent},
    // {path:'faq', component:FaqComponent},

    {path: 'terms-of-service', component: TermsOfServiceComponent},
    // {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'contact', component: ContactUsComponent},
    { path: 'view/:page', component: ViewPageComponent },

    // {path: 'site-map', component: SiteMapComponent},
    // {path: 'event-enquiry', component: EventEnquiryComponent},
    // {path: 'film-crew-catering', component: FilmCrewCateringComponent},
    // {path: 'family-gatherings', component: FamilyGatheringsComponent},
    // {path: 'event', component: EventComponent},
    // {path: 'our-full-menu', component: FullMenuComponent},
    // {path: 'cutomize-menu', component: CustomizeMenuComponent},
    // {path: 'popular-catering-combos', component: PopularCateringCombosComponent},
    // {path: 'canapes-menu', component: CanapesMenuComponent},
    // {path: 'live-dosa-station', component: LiveDosaStationComponent},
    // {path: 'customer-locations', component: CustomerLocationsComponent},
    // {path: 'about-us', component: SuccessStoryComponent},
    // {path: 'scp', component: ScpComponent},
    // {path:'festival-and-events',component: FestivalAndSocialEventsComponent},
    // {path:'weddings',component: WeddingsComponent},
    // {path:'corporate_events',component: CorporateEventsComponent},
    // {path:'sports_events',component: SportsEventsComponent},
    // {path:'charity_fundraising_events',component: CharitFundraisingEventsComponent},
    // {path:'reviews',component: ReviewsComponent},
    // {path:'sitemap',component: SitemapComponent},
    // {path:'veg-combos',component: VegMenuComponent},
    // {path:'veg-and-nonveg-combos',component: VegOrNonVegCombosComponent},
    // {path:'festivel-combo',component: FestivelComboComponent},
    // {path: 'pure-vegmenu', component: VegMenuListComponent},
    // {path:'best-selling-combos',component: BestSellingCombosComponent},
    // {path:'lunch-or-dinner-combos',component: LunchOrDinnerCombosComponent},
    // {path:'breakfast-combos',component: BreakfastComboComponent},
    // {path:'thank_you',component: ThankYouComponent},
    // {path:'live-stations',component: LiveStationMenuListComponent},
    // {path:'wedding-combos',component: WeddingCombosComponent},
    // {path:'subscribeNewsletter' ,component:SubscribeComponent},
    // {path:'thank_you_newsletter',component:ThankyouNewsLetterComponent},
    // {path:'unsubscribeNewsletter' ,component:UnsubscribeNewsLetterComponent},
    // {path:'audit-and-certificates',component:AuditAndCertificatesComponentComponent},
    // {path:'error',component: ErrorPageComponent},
    // {path:'leave_your_feedback',component:LeaveYourFeedBackComponent},
    {path:'error',component: ErrorPageComponent},
    {path: '**', component: ErrorComponent}
   /*
    // Here add new pages component
    */
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
