<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Request a Free Quote</li>
            </ul>
            <h2>Request a Free Quote</h2>
        </div>
    </div>
    <
</div>

<div class="contact-area ptb-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">INDIAN AROMA CATERERS</span>
                    <h2>Get in Touch</h2>
                    <p>We can't wait to hear about your event...
                    </p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>33 Woodford Avenue, Gants Hill, IG2 6UF, England, UK</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Whatsapp: <a href="tel:+447916507777">079 1650 7777</a></p>
                            <p>Phone: <a href="tel:+442085507777">020 8550 7777</a></p>
                            <p>Mobile: <a href="tel:+447916507777">079 1650 7777</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-envelope'></i>
                            </div>
                            <h3>Email</h3>
                            <p><a href="mailto:contact@indianaromacaterers.co.uk">contact@indianaromacaterers.co.uk</a></p>
                            <p><a href="mailto:hello@indianaromacaterers.co.uk">hello@indianaromacaterers.co.uk</a></p>
                        </li>

                        <li>
                            <div class="icon">
                                <i class='bx bx-money'></i>
                            </div>
                            <h3>General Enquiry</h3>
                            <p><a routerLink="/contact">Contact us for General Enquiry</a></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Get a Quote
                    </h2>
                    <p>Please fill out the following online form and a member of our team will call you as soon as possible to discuss the Food Menu and any other requirements.</p>
                    <form [formGroup]="reactiveForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <select formControlName="title" [(ngModel)]="title" (click)="titleinvalid()" class="form-select" id="title">
                                        <option [ngValue]="null" disabled selected>Select Title*</option>
                                        <option>Mr</option>
                                        <option>Mrs</option>
                                        <option>Miss</option>
                                        <option>Ms</option>
                                        <option>Dr</option>
                                    </select>
                                    <div class="alert alert-danger" *ngIf="titleinvalidError">Title is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input formControlName="name" class="form-control" (keyup)="nameinvalid()" placeholder="Your Full Name*">
                                    <div class="alert alert-danger" *ngIf="nameinvalidError">
                                        <div>Full Name is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input name="email" type="email" formControlName="email" (keyup)="emailinvalidError()" class="form-control" placeholder="Email*"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div class="alert alert-danger" *ngIf="submitted && f.email.errors ">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <!-- <input name="phone" type="text" (keyup)="phoneinvalidError()" formControlName="phone" class="form-control" placeholder="Phone*"> -->
                                    <ngx-intl-tel-input
                                    [cssClass]="'custom'"
                                    [preferredCountries]="preferredCountries"
                                    [customPlaceholder]="'phone*'"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[
                                      SearchCountryField.Iso2,
                                      SearchCountryField.Name
                                    ]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.UnitedKingdom"
                                    [maxLength]="15"
                                    [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode"
                                    [numberFormat]="PhoneNumberFormat.National"
                                    name="phone"
                                    (keyup)="phoneinvalidError()"
                                    formControlName="phone"
                                  >
                                  </ngx-intl-tel-input>
                                  <div class="alert alert-danger" *ngIf=" phn && f.phone.invalid "  >
                                    <div *ngIf="f.phone.errors.required">Phone is required</div>
                                    <div *ngIf="f.phone.invalid">Enter a valid Phone Number</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="servicetype" [(ngModel)]="servicetype" (click)="serviceTypeinvalid()" formControlName="servicetype" class="form-select" (change)="onChange($event.target.value)">
                                        <option  [ngValue]="null"  disabled selected>Choose Catering Occasion*</option>
                                        <!-- <option>Live Dosa Station</option> -->
                                        <option>Family Gathering</option>
                                        <option>Festival & Social Events</option>
                                        <option>Weddings</option>
                                        <option>Film Crew Catering</option>
                                        <option>Corporate Events</option>
                                        <option>Sports Events</option>
                                        <option>Charity Fundraising Events</option>
                                         <option>Other</option></select>
                                    <div class="alert alert-danger" *ngIf="serviceTypeError">Catering Occasion is required.</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12" *ngIf="otherData">
                                <div class="form-group">
                                    <input name="occasiondescription" type="text" formControlName="occasiondescription" class="form-control" placeholder="Enter Occasion Description">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input name="eventdate" type="date" formControlName="eventdate" [min]="now" [max]="maxBirthDate" class="form-control"  placeholder="Occasion Date  (dd-mm-yyyy) - Select Date">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input name="eventstarttime" type="time" formControlName="eventstarttime"  class="form-control" placeholder="Occasion Start Time">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input name="numberofguests" type="text" formControlName="numberofguests"  class="form-control" placeholder="Guest Number (Estimate)">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input name="venueinmind" type="text" class="form-control" formControlName="venueinmind" placeholder="Venue Name/Address ">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="message" (keyup)="messageinvalidError()" name="message" cols="30" rows="5" class="form-control" placeholder="Tell us about your catering requirements...*"></textarea>
                                    <div class="alert alert-danger" *ngIf="messageinvalid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label class="form-check-label text-white" for="flexCheckDefault" >
                                        By sending this form, I confirm that I have read and accept the
                                        <a routerLink="/terms-of-service">Terms</a> and <a routerLink="/privacy-policy">Privacy Policy.</a>
                                    </label>
                                  </div>

                            </div>
                            <div class="col-lg-12 col-md-12 d-flex">
                                <button type="submit" class="default-btn" (click)="submitData()">Send Message<span></span></button>
                                <button class="default-btn" (click)="dataReset()">Reset<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.494421795278!2d0.0625836157725634!3d51.57750127964681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a6ea02a68f01%3A0x3f9f7a97f7ff7b5a!2s33%20Woodford%20Ave%2C%20Gants%20Hill%2C%20Ilford%20IG2%206UF!5e0!3m2!1sen!2suk!4v1645459915806!5m2!1sen!2suk" ></iframe>

</div>
