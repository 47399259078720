import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    selectedItem: any;
    activeButton: any;
    pages : any[] = [];
    page : string = '';

    constructor(
      private activeRoute:ActivatedRoute,
      private data:DataService,
      private router:Router
    ) { 
      activeRoute.params.subscribe(params=>{
        this.page = null;
        if(params["page"]){
            this.page = params["page"]
        }

    })
    }

    ngOnInit(): void {
      this.getHeaderContent()
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }


  showPhase(event){
    this.activeButton = event;
  }


  getHeaderContent(){
    this.data.getHeaderContent().subscribe((res:any)=>{
        this.pages = res;

    },err=>{

    })
}

filterPosts(page:any){
    this.router.navigate([`/view/${page}`])
  }

  pagesLengthMoreThenFour(){
    return this?.pages?.length > 4;
  }

  getFirstFourPages(){
    if(this.pagesLengthMoreThenFour()){
      return this.pages.slice(0,4)
    }
    return this.pages;
  }

  getLastPages(){
    if(this.pagesLengthMoreThenFour()){
      return this.pages.slice(4)
    }
    return [];
  }

  isCurrentPage(page:any){
    const active = this.page === page;
    return active;
  }
}
