import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from "ngx-accordion";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PremiumAccessComponent } from './components/common/premium-access/premium-access.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { DistanceLearningComponent } from './components/pages/distance-learning/distance-learning.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { SuccessStoryComponent } from './components/pages/success-story/success-story.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { CoursesComponent } from './components/common/courses/courses.component';
import { TestimonialsComponent } from './components/common/testimonials/testimonials.component';
import { FunfactsStyleOneComponent } from './components/common/funfacts-style-one/funfacts-style-one.component';
import { InstructorStyleTwoComponent } from './components/common/instructor-style-two/instructor-style-two.component';
import { TopCategoriesComponent } from './components/common/top-categories/top-categories.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { DistancePopularCoursesComponent } from './components/pages/distance-learning/distance-popular-courses/distance-popular-courses.component';
import { DistanceBannerComponent } from './components/pages/distance-learning/distance-banner/distance-banner.component';
import { DistanceBoxesComponent } from './components/pages/distance-learning/distance-boxes/distance-boxes.component';
import { DistanceAboutComponent } from './components/pages/distance-learning/distance-about/distance-about.component';
import { UpcomingEventsComponent } from './components/common/upcoming-events/upcoming-events.component';
import { BoxesComponent } from './components/common/boxes/boxes.component';
import { OurStoryComponent } from './components/pages/success-story/our-story/our-story.component';
import { CateringServicesComponent } from './components/pages/catering-services/catering-services.component';
import { MenusComponent } from './components/pages/menus/menus.component';
import { OurGalleryComponent } from './components/pages/our-gallery/our-gallery.component';
import { AllergyAdviceComponent } from './components/pages/allergy-advice/allergy-advice.component';
import { CookiePolicyComponent } from './components/pages/cookie-policy/cookie-policy.component';
import { SiteMapComponent } from './components/pages/site-map/site-map.component';
import { EventEnquiryComponent } from './components/pages/event-enquiry/event-enquiry.component';
import { GalleryServicesBlockComponent } from './components/pages/gallery-services-block/gallery-services-block.component';
import { FilmCrewCateringComponent } from './components/pages/film-crew-catering/film-crew-catering.component';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EventComponent } from './components/common/event/event.component';
import { FamilyGatheringsComponent } from './components/pages/family-gatherings/family-gatherings.component';
import { ServicesButtonsBlockComponent } from './components/common/services-buttons-block/services-buttons-block.component';
import { FullMenuComponent } from './components/pages/full-menu/full-menu.component';
import { PopularCateringCombosComponent } from './components/pages/popular-catering-combos/popular-catering-combos.component';
import { LiveDosaStationComponent } from './components/pages/live-dosa-station/live-dosa-station.component';
import { CustomerLocationsComponent } from './components/pages/customer-locations/customer-locations.component';
import { ScpComponent } from './components/pages/scp/scp.component';
import { FestivalAndSocialEventsComponent } from './components/pages/festival-and-social-events/festival-and-social-events.component';
import { WeddingsComponent } from './components/pages/weddings/weddings.component';
import { CorporateEventsComponent } from './components/pages/corporate-events/corporate-events.component';
import { SportsEventsComponent } from './components/pages/sports-events/sports-events.component';
import { CharitFundraisingEventsComponent } from './components/pages/charit-fundraising-events/charit-fundraising-events.component';
import { ReviewsComponent } from './components/pages/reviews/reviews.component';
import { SitemapComponent } from './components/pages/sitemap/sitemap.component';
import { VegMenuComponent } from './components/pages/veg-menu/veg-menu.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BestSellingCombosComponent } from './components/pages/best-selling-combos/best-selling-combos.component';
import { LunchOrDinnerCombosComponent } from './components/pages/lunch-or-dinner-combos/lunch-or-dinner-combos.component';
import { BreakfastComboComponent } from './components/pages/breakfast-combo/breakfast-combo.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ThankYouComponent } from './components/pages/thank-you/thank-you.component';
import { FeaturesStyleOneComponent } from './components/common/features-style-one/features-style-one.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { VegMenuListComponent } from './components/pages/veg-menu-list/veg-menu-list.component';
import { LiveStationMenuListComponent } from './components/pages/live-station-menu-list/live-station-menu-list.component';
import { WeddingCombosComponent } from './components/pages/wedding-combos/wedding-combos.component';
import { ChoiceOfMenusComponent } from './components/pages/distance-learning/choice-of-menus/choice-of-menus.component';
import { MenuListServiceService } from './components/pages/menu-list-service.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UnderscoreRemovePipe } from './components/pages/underscore-remove.pipe';
import { VegOrNonVegCombosComponent } from './components/pages/veg-or-non-veg-combos/veg-or-non-veg-combos.component';
import { ThankyouNewsLetterComponent } from './components/common/thankyou-news-letter/thankyou-news-letter.component';
import { CustomizeMenuComponent } from './customize-menu/customize-menu.component';
import { AuditAndCertificatesComponentComponent } from './components/common/audit-and-certificates-component/audit-and-certificates-component.component';
import { FunfactsStyleTwoComponent } from './components/common/funfacts-style-two/funfacts-style-two.component';
import { LeaveYourFeedBackComponent } from './components/common/leave-your-feed-back/leave-your-feed-back.component';
import { UnsubscribeNewsLetterComponent } from './components/common/Unsubscribe-newsletter/Unsubscribe-Newsletter.component';
import { blogdetailsComponent } from './components/common/blog-details/blogdetails.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchFilterPipe } from './components/common/blog/search-filter.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FestivelComboComponent } from './components/pages/festivelCombo/festivelCombo.component';
import { CanapesMenuComponent } from './components/pages/canapes-menu/canapes-menu.component';
import { AwardsComponent } from './components/pages/distance-learning/awards/awards.component';
import { TeacherComponent } from './components/pages/teacher/teacher.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { OrderComponent } from './components/pages/distance-learning/order/order.component';
import { ViewPageComponent } from './components/common/view-page/view-page.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    PremiumAccessComponent,
    SubscribeComponent,
    DistanceLearningComponent,
    ContactUsComponent,
    GalleryComponent,
    SuccessStoryComponent,
    ErrorComponent,
    ComingSoonComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    CoursesComponent,
    TestimonialsComponent,
    FunfactsStyleOneComponent,
    InstructorStyleTwoComponent,
    TopCategoriesComponent,
    FeedbackComponent,
    DistancePopularCoursesComponent,
    DistanceBannerComponent,
    DistanceBoxesComponent,
    DistanceAboutComponent,
    UpcomingEventsComponent,
    BoxesComponent,
    OurStoryComponent,
    CateringServicesComponent,
    ProfileComponent,
    MenusComponent,
    OurGalleryComponent,
    AllergyAdviceComponent,
    CookiePolicyComponent,
    SiteMapComponent,
    EventEnquiryComponent,
    GalleryServicesBlockComponent,
    FilmCrewCateringComponent,
    FeaturesStyleOneComponent,
    EventComponent,
    FamilyGatheringsComponent,
    ServicesButtonsBlockComponent,
    FullMenuComponent,
    PopularCateringCombosComponent,
    LiveDosaStationComponent,
    CustomerLocationsComponent,
    ScpComponent,
    FestivalAndSocialEventsComponent,
    WeddingsComponent,
    CorporateEventsComponent,
    SportsEventsComponent,
    CharitFundraisingEventsComponent,
    ReviewsComponent,
    SitemapComponent,
    VegMenuComponent,
    BestSellingCombosComponent,
    LunchOrDinnerCombosComponent,
    BreakfastComboComponent,
    ErrorPageComponent,
    ThankYouComponent,
    LeaveYourFeedBackComponent,
    VegMenuListComponent,
    LiveStationMenuListComponent,
    WeddingCombosComponent,
    ChoiceOfMenusComponent,
    UnderscoreRemovePipe,
    VegOrNonVegCombosComponent,
    FestivelComboComponent,
    ThankyouNewsLetterComponent,
    CustomizeMenuComponent,
    UnsubscribeNewsLetterComponent,
    AuditAndCertificatesComponentComponent,
    blogdetailsComponent,
    SearchFilterPipe,
    FunfactsStyleTwoComponent,
    CanapesMenuComponent,
    AwardsComponent,
    TeacherComponent,
    FaqComponent,
    OrderComponent,

    BlogComponent,
    ViewPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    CountUpModule,
    StickyNavModule,
    TabsModule,
    NgxScrollTopModule,
    LightboxModule,
    AccordionModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxIntlTelInputModule,
    TooltipModule.forRoot()
  ],
  providers: [MenuListServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
