import { HttpClient, HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, InjectionToken, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/core/services/data.service";



@Component({
    selector:'app-blogdetails',
    templateUrl:'./blogdetails.component.html',
    styleUrls:['./blogdetails.component.scss']
})

export class blogdetailsComponent implements OnInit{

    submitted : boolean
    blogDetail: any = [];
  blogForm:FormGroup;
    list: Object;
    blog_comments: any;
    nameinvalidError: boolean;
    emailinvalid: boolean;
    pid: any;
    blogDetailer: any;
    postBodydata: any =[];
    validatingForm: FormGroup;
    reply:FormGroup;
    closeResult = '';
    commentId:any;
    comment_reply:Object;
    object : any
    emailPattern = "^[a-zA-Z0-9_\\+-]+(\\.[a-zA-Z0-9_\\+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]{2,4})$";
    rnameinvalidError: boolean;
    remailinvalid: boolean;
    rsubmitted: boolean;
    commentinvalid: boolean;
    BlogPostData: any = {};
    postId: any;
    isLoading = true;
    responseError = '';
    searchText = '';
  constructor(private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router : Router,
    private modalService: NgbModal,
    private data:DataService) {
        activatedRoute.params.subscribe(res=>{
            ;
            this.postId = res.post;
            if(this.postId){
                this.getBlogDetails(this.postId);
            }
        })
     }
  ngOnInit(): void {


    this.blogForm = this.fb.group({
        name:['',Validators.required],
        email:['',[Validators.required,Validators.email,Validators.pattern(this.emailPattern)]],

        commentContent:['']


    });
    this.reply=this.fb.group({
        name:[''],
        email:[''],

        commentReply:['']
    })







    // this.activatedRoute.queryParams.subscribe(params => {
    //     this.pid = params['id'];
    //      let queryParams = new HttpParams();
    //     queryParams = queryParams.append("id",this.pid);

    // this.http.get('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/blog/post', {params:queryParams} ).subscribe(res=>{

    //     const blogData = res;
    //     this.blogDetailer = blogData;
    //    this.blog_comments = this.blogDetailer.blog_comments;
    //    this.blogDetail = this.blogDetailer.blog_details;
    //    this.postBodydata = this.blogDetail.postContent;
    //     // ;
    //     
    //     return res;
    // })

    // });


  }



 async  getBlogDetails(id:any){
    this.isLoading = true;
    this.responseError = '';
    await this.data.getPostById(id).then((res:any)=>{
        const BlogDetailsDiv = document.getElementById('blogDescriptionDiv');
        this.BlogPostData = res;
        BlogDetailsDiv.innerHTML = this.BlogPostData?.description;
        const similarArticles = res?.similarArticles;
        if(!res?.nextPost){
            this.BlogPostData.nextPost = similarArticles?.length ?  similarArticles[0] : null;
        }
        if(!res?.prevPost){
            this.BlogPostData.prevPost = similarArticles?.length ?  similarArticles[similarArticles?.length-1] : null;
        }
        ;
    }).catch(err=>{
        ;
        this.responseError = err?.error?.error;
    }).finally(()=>{
        this.isLoading = false;
    })
  }




 cid(crid){
    this.commentId=crid;
    return this.commentId;
 }
  open(content) {
    this.modalService.open(content,
   {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
         `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  jsobj(object){
    // for(i in this.blog_comments[i].commentReplies){
        // this.object=this.blog_comments[i].commentReplies
  this.comment_reply= Object.keys(object).map(e=>object[e]);
    // ;
    // }

  return this.comment_reply;

}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  nameinvalid() {
    if(this.blogForm.get('name').value == "" || this.blogForm.get('name').value == undefined){
            this.nameinvalidError = true;
        } else {
            this.nameinvalidError = false;
        }
    }
    get f() { return this.blogForm.controls; }
    emailinvalidError() {
        if(this.blogForm.get('email').value == "" || this.blogForm.get('email').value == undefined ){
            this.emailinvalid = true;
            this.submitted = true;

        } else {
            this.emailinvalid = false;
            this.submitted = true;

        }
    }
    replynameinvalid() {
        if(this.reply.get('name').value == "" || this.reply.get('name').value == undefined){
                this.rnameinvalidError = true;
            } else {
                this.rnameinvalidError = false;
            }
        }
        get rf() { return this.reply.controls; }
        replyCommentInvalid(){
            if(this.reply.get('commentReply').value=="" || this.reply.get('commentReply').value == undefined){
                this.commentinvalid=true;
            }
            else{
                this.commentinvalid=false;
            }
        }
        replyemailinvalidError() {
            if(this.reply.get('email').value == "" || this.reply.get('email').value == undefined ){
                this.remailinvalid = true;
                this.rsubmitted = true;

            } else {
                this.remailinvalid = false;
                this.rsubmitted = true;

            }
        }

    submitReplyData() {
        for(const rep in this.blog_comments[0].commentReplies){
        }
        ;
        ;
        if(this.reply.invalid) {
            this.replynameinvalid();
            this.replyemailinvalidError();
            this.replyCommentInvalid();
            this.reply.invalid;
        }
        else{

            const replyData=
            {
                "commentAuthor": {
                  "name": this.reply.get('name').value,
                  "email": this.reply.get('email').value,

                },
                "commentContent": this.reply.get('commentReply').value,
                "commentId": this.commentId,
                "postId": this.pid
             }
        this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/blog/post/comment/reply',replyData).subscribe(res =>{
            this.ngOnInit();
          return res;
      })
      this.modalService.dismissAll();
        this.reply.reset();
        }

    }


    submitData() {

        ;
        if(this.blogForm.invalid) {
            this.nameinvalid();
            this.emailinvalidError();
            this.blogForm.invalid;
        } else {
            this.submitted=false;
            const data = {
                "postId":this.pid,
                "commentContent":this.blogForm.get('commentContent').value,
                "commentAuthor":  {
                    "name":this.blogForm.get('name').value,
                    "email": this.blogForm.get('email').value,

                }
           }
            this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/blog/post/comment',data).subscribe(res =>{
                this.ngOnInit();
              return res;
          })
          this.blogForm.reset();
        }
    }

    openPostDetails(postUrl:any){
        if(postUrl == this.postId)return;
        this.BlogPostData = {};
        this.router.navigate([`/blog-details/${postUrl}`])
      }

      searchBlogPosts(){
        this.router.navigate(['/'],{queryParams:{searchText:this.searchText}})
      }

      getPostShareUrl(){
        return encodeURIComponent(window.location.href);
      }

      searchNow($event:any){
        if($event.key == 'Enter'){
            this.searchBlogPosts()
        }
      }
}
