import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

    constructor(private http: HttpClient) { }

    getEventData(x: any) {
      const dev = this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/eventinquiry/dynamodb', x)
      const prod = this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/eventinquiry/airtable',x)
      const uat = this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/eventinquiry/thankyouemail',x)

      forkJoin([dev,prod,uat]).subscribe(res =>{
          return res;
      })
    }
}
